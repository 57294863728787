import { Box, Container, Flex, Text, useColorMode } from "@chakra-ui/react";
import React, { useEffect } from "react";
import {useStaticQuery,graphql } from "gatsby";
import { Helmet } from "react-helmet";
import ImgTextModule from "../components/ImgTextModule";
import NewsListItem from "../components/NewsListItem";
import NewsListItemHref from "../components/NewsListItemHref";
import QuadKachelnInvRelations from "../components/QuadKachelnInvRelations";
import FadeInAnimation from "../DefinitionComponents/Animation-Components/FadeInAnimation";

const NewsFrontpage = () => {

  const data = useStaticQuery(graphql`
  query getNews3 {
    allWpNews(sort: {fields: date, order: DESC},limit: 3) {
      nodes {
        dateGmt
        date(formatString: "DD.MM.yyyy", locale: "DE")
        newsDatum
        newsTitle
        newsUrl
        pDFOderURL
        pdfNews {
          filename
          mediaType
          localFile {
            absolutePath
            relativePath
          }
        }
      }
    }
 }
   `);
   const DIDDS = data.allWpNews.nodes;


  return (
 


      <Container
        variant="layoutContainer"
        px={{ base: 0, lg: 10 }}
        py={{ base: 0, lg: 10 }}
      >
        <Flex
          flexWrap="wrap"
          flexDirection="column"
          justifyContent={"space-between"}
          
        >
  {DIDDS.map((News)=>(  
      <>

        {News.pDFOderURL ? (  
               <NewsListItem
               datum={News.newsDatum}
               textinhalt={News.newsTitle}
                         pdflink={News.pdfNews.localFile.relativePath}
                       /> 
             
        )
            :
 (      
          <NewsListItemHref
          datum={News.newsDatum}
          textinhalt={News.newsTitle}
          actionlink={News.newsUrl  }
        />
 )
      }
         
</>
 ))}
        </Flex>
      </Container>
   
  
  );
};

export default NewsFrontpage;
